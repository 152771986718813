import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { DataWrapper } from "../store/helpers";
import {
  RECENT_TRANSACTIONS_WORMHOLE,
  TOTAL_TRANSACTIONS_WORMHOLE,
  VAA_EMITTER_ADDRESSES,
} from "../utils/consts";

export type TransactionCount = {
  totalAllTime: number;
  total24h: number;
  mostRecent: any; //This will be a signedVAA
};

const mergeResults = (totals: any, recents: any): TransactionCount | null => {
  let totalAllTime = 0;
  let total24h = 0;
  VAA_EMITTER_ADDRESSES.forEach((address: string) => {
    let totalAll = (totals?.TotalCount && totals.TotalCount[address]) || 0;
    let total24 = (totals?.LastDayCount && totals.LastDayCount[address]) || 0;

    totalAllTime += totalAll;
    total24h += total24;
  });

  return {
    totalAllTime,
    total24h,
    mostRecent: null,
  };
};

const useTransactionCount = (): DataWrapper<TransactionCount> => {
  const [totals, setTotals] = useState(null);
  const [recents, setRecents] = useState(null);

  const [loadingTotals, setLoadingTotals] = useState(false);
  const [loadingRecents, setLoadingRecents] = useState(false);

  const [totalsError, setTotalsError] = useState("");
  const [recentsError, setRecentsError] = useState("");

  useEffect(() => {
    let cancelled = false;
    setLoadingTotals(true);
    axios.get(TOTAL_TRANSACTIONS_WORMHOLE).then(
      (results) => {
        if (!cancelled) {
          setTotals(results.data);
          setLoadingTotals(false);
        }
      },
      (error) => {
        if (!cancelled) {
          setTotalsError("Unable to retrieve transaction totals.");
          setLoadingTotals(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    let cancelled = false;
    setLoadingRecents(true);
    axios.get(RECENT_TRANSACTIONS_WORMHOLE).then(
      (results) => {
        if (!cancelled) {
          setRecents(results.data);
          setLoadingRecents(false);
        }
      },
      (error) => {
        if (!cancelled) {
          setRecentsError("Unable to retrieve recent transactions.");
          setLoadingRecents(false);
        }
      }
    );
  }, []);

  return useMemo(() => {
    const data = mergeResults(totals, recents);
    return {
      isFetching: loadingRecents || loadingTotals,
      error: totalsError || recentsError,
      receivedAt: null,
      data: data,
    };
  }, [
    totals,
    recents,
    loadingRecents,
    loadingTotals,
    recentsError,
    totalsError,
  ]);
};

export default useTransactionCount;
